var render, staticRenderFns
import script from "./fancyDynamicTableViewItem.vue?vue&type=script&lang=js"
export * from "./fancyDynamicTableViewItem.vue?vue&type=script&lang=js"
import style0 from "./fancyDynamicTableViewItem.vue?vue&type=style&index=0&id=71c64395&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "fancyDynamicTableViewItem.vue"
export default component.exports